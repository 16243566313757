import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"

const reducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return { ...initialState, loginMessage: action.data }
  }
  if (action.type === "SET_STATE") {
    return { ...state, [action.data.prop]: action.data.val }
  }
  if (action.type === "LOADING_START") {
    console.log("load 'em up")
    return { ...state, isLoading: true }
  }
  if (action.type === "LOADING_NOTIFY_CREATE") {
    console.log("load 'em up")
    return { ...state, isLoadingNotifyCreate: true }
  }
  if (action.type === "LOADING_NOTIFY_RESET") {
    return {
      ...state,
      isLoadingNotifyCreate: false,
      forms: {
        ...state.forms,
        notify: {},
      },
    }
  }
  if (action.type === "LOADING_END") {
    return {
      ...state,
      isLoading: false,
      isLoadingVideoCreatedGrouped: false,
      isLoadingVideoUsers: false,
      isLoadingVideoMini: false,
      isLoadingNotifyCreate: false,
    }
  }
  if (action.type === "LOADING_SUBMISSION_START") {
    console.log("load 'em up")
    return { ...state, loadingSubmission: true }
  }
  if (action.type === "LOADING_MINI_START") {
    console.log("load 'em up")
    return { ...state, isLoadingMini: { [action.data.sessionid]: true } }
  }
  if (action.type === "LOADING_CANVAS_IMAGE") {
    return { ...state, isLoadingCanvasImage: action.data }
  }
  if (action.type === "CAMPAIGNS_REPLACE") {
    console.log("campaigns-replace")
    return { ...state, isLoading: false, campaign: action.data }
  }
  if (action.type === "AGREEMENT_REPLACE") {
    console.log("agreement-replace")
    return { ...state, isLoading: false, agreements: action.data }
  }
  if (action.type === "NOTIFY_REPLACE") {
    console.log("campaigns-replace")
    return { ...state, isLoading: false, notify: action.data }
  }
  if (action.type === "CAMPAIGNS_TOACTIVATE_REPLACE") {
    console.log("campaigns-toactivate-replace")
    return { ...state, isLoading: false, campaignactivate: action.data }
  }
  if (action.type === "CAMPAIGN_ACTIVATE_REPLACE") {
    console.log("CAMPAIGN_ACTIVATE_REPLACE")
    return {
      ...state,
      loadingSubmission: false,
      submitted: true,
      campaignactivated: action.data,
    }
  }

  if (action.type === "TEMPLATE_FETCH_START") {
    console.log("TEMPLATE_FETCH_START")

    return {
      ...state,
      isLoadingTemplateFetch: true,
    }
  }
  if (action.type === "TEMPLATE_FETCH_END") {
    console.log("TEMPLATE_FETCH_END")

    return {
      ...state,
      isLoadingTemplateFetch: false,
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_START") {
    console.log("TEMPLATE_CREATE_UPDATE_START")

    return {
      ...state,
      isLoadingTemplateCreate: true,
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_END") {
    console.log("TEMPLATE_CREATE_UPDATE_END")

    return {
      ...state,
      isLoadingTemplateCreate: false,
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_COMPLETE") {
    console.log("TEMPLATE_CREATE_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingTemplateCreate: false,
      isLoadingTemplateFetch: false,
      forms: {
        ...state.forms,
        savetemplate: {},
        edittemplate: {},
        tag: {},
      },
    }
  }
  if (action.type === "TEMPLATE_EDIT_UPDATE_START") {
    console.log("TEMPLATE_EDIT_UPDATE_START")

    return {
      ...state,
      isLoadingTemplateCreate: true,
    }
  }
  if (action.type === "TEMPLATE_EDIT_UPDATE_COMPLETE") {
    console.log("TEMPLATE_EDIT_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingTemplateCreate: false,
      forms: {
        ...state.forms,
        edittemplate: {},
        savetemplate: {},
      },
    }
  }
  if (action.type === "CLEAR_FORM_FIELD") {
    console.log("CLEAR_FORM_FIELD")

    let val = {}
    if (
      action &&
      action.data &&
      action.data.prop &&
      action.data.prop === "workingtags"
    ) {
      val = []
    }
    return {
      ...state,
      forms: {
        ...state.forms,
        [action.data.prop]: val,
      },
    }
  }

  if (action.type === "SHARE_FIELD_UPDATE") {
    console.log("SHARE_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)
    if (action.data.val === "reset") {
      return {
        ...state,
        isLoading: false,
        share: {},
      }
    } else {
      return {
        ...state,
        isLoading: false,
        share: {
          ...state.share,
          [action.data.prop]: action.data.val,
        },
      }
    }
  }
  if (action.type === "ACTIVETOOL_FIELD_UPDATE") {
    // console.log("ACTIVETOOL_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      activeTool: action.data,
    }
  }
  if (action.type === "EMAILVERIFY_FIELD_UPDATE") {
    // console.log("EMAILVERIFY_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      emailVerifyMode: action.data,
    }
  }
  if (action.type === "EMAILVERIFY_SEND_ERRORS") {
    return {
      ...state,
      forms: {
        ...state.forms,
        userdetailemail: {
          ...state.forms.userdetailemail,
          error: action.data ? action.data : "",
        },
      },
    }
  }

  if (action.type === "EMAILVERIFY_UPDATE_COMPLETE") {
    console.log("EMAILVERIFY_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    let returnObj = {
      email: state.userdetail.email,
      mobile: state.userdetail.mobile,
      verifycode: "",
      error: "",
    }

    let errorMsg
    if (action.error) errorMsg = action.error
    return {
      ...state,
      emailVerifyMode: action.data,
      forms: {
        ...state.forms,
        userdetailemail: {
          ...returnObj,
          error: errorMsg ? errorMsg : "",
        },
      },
      userdetailemail: returnObj,
    }
  }
  if (action.type === "FORMS_FIELD_UPDATE") {
    console.log("FORMS_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "PUBLISHER_FIELD_UPDATE") {
    console.log("PUBLISHER_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      publisher: {
        ...state.publisher,
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "PUBLISHER_TEMPLATE_REMOVE") {
    console.log("PUBLISHER_TEMPLATE_REMOVE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      isLoadingTemplateCreate: false,
      isLoadingTemplateDeleteCompleted: true,
      publisher: {
        ...state.publisher,
        templates: state.publisher.templates.filter(
          (template) => template.id !== action.data.id
        ),
      },
      forms: {
        ...state.forms,
        edittemplate: {},
        savetemplate: {},
      },
    }
  }
  if (action.type === "PUBLISHER_TEMPLATE_DELETE_RESET") {
    console.log("PUBLISHER_TEMPLATE_DELETE_RESET")

    return {
      ...state,
      isLoadingTemplateDeleteCompleted: false,
    }
  }

  if (action.type === "PROFILE_EDIT_COPY") {
    console.log("PROFILE_EDIT_COPY")
    console.log(state.userdetail)
    return {
      ...state,
      forms: {
        ...state.forms,
        userdetail: action.data ? action.data : state.userdetail,
        userdetailemail: action.data
          ? {
              email: action.data.email,
              mobile: action.data.mobile,
              displayname: action.data.displayname,
              verifycode: "",
              error: "",
            }
          : state.userdetailemail,
      },
    }
  }
  if (action.type === "PROFILE_EDIT_EMAIL_COPY") {
    console.log("PROFILE_EDIT_EMAIL_COPY")
    console.log(state.userdetailemail)
    return {
      ...state,
      forms: {
        ...state.forms,
        userdetailemail: action.data ? action.data : state.userdetailemail,
      },
    }
  }

  if (action.type === "USER_LOGIN") {
    console.log("createStore - USER_LOGIN - action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      userlogin: action.data,
      usersub: action.data.sub,
      usererror: {},
    }
  }

  if (action.type === "USER_LOGIN_ERROR") {
    return { ...state, isLoading: false, usererror: action.data }
  }
  if (action.type === "USER_PROFILE_DETAIL_REPLACE") {
    console.log("createStore - USER_PROFILE_DETAIL_REPLACE - action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      isProfileUpdateComplete: true,
      userdetail: action.data,
      userdetailemail: {
        email: action.data.email,
        mobile: action.data.mobile,
        displayname: action.data.displayname,
        verifycode: "",
        error: "",
      },
    }
  }
  if (action.type === "USER_PROFILE_DETAIL_COMPLETE") {
    return {
      ...state,
      isProfileUpdateComplete: false,
    }
  }
  if (action.type === "COMPANY_DETAIL_REPLACE") {
    console.log("createStore - COMPANY_DETAIL_REPLACE - action.data")
    console.log(action.data)
    // if (action.data.user) {
    //   delete action.data.user.email
    // }
    return {
      ...state,
      isLoading: false,
      company: action.data.company,
      userdetail: action.data.user,
      userdetailemail: {
        email: action.data.user.email,
        mobile:
          action.data.user && action.data.user.mobile
            ? action.data.user.mobile
            : "",
        displayname: action.data.user.displayname,
        verifycode: "",
        error: "",
      },
      usererror: {},
    }
  }

  if (action.type === "SOCIALMODAL_REPLACE") {
    console.log("SOCIALMODAL_REPLACE")
    return {
      ...state,
      socialmodal: action.data,
      isLoadingMediaPreview: true,
    }
  }
  if (action.type === "SOCIALMODAL_FINISHED") {
    console.log("SOCIALMODAL_FINISHED")
    return {
      ...state,
      isLoadingMediaPreview: false,
      imageexport: action.data,
    }
  }

  if (action.type === "UPDATE_FORM_FIELD") {
    console.log("UPDATE_FORM_FIELD")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        [action.data.group]: {
          ...state.forms[action.data.group],
          [action.data.prop]: action.data.val,
        },
      },
    }
  }
  if (action.type === "UPDATE_FORM_FIELD_SINGLE") {
    console.log("UPDATE_FORM_FIELD_SINGLE")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        [action.data.prop]: action.data.val,
      },
    }
  }

  if (action.type === "UPDATE_FORM_FIELD_USERGROUP") {
    console.log("UPDATE_FORM_FIELD_USERGROUP")

    const { group, index, fname, lname, email } = action.data

    if (group === "usersmodify" || group === "usersmodifyemail") {
      return {
        ...state,
        isLoading: false,
        company: {
          ...state.company,
          users: state.company.users.map((userDetail, i) =>
            i === index
              ? {
                  ...userDetail,
                  index,
                  fname: fname ? fname : userDetail.fname,
                  lname: lname ? lname : userDetail.lname,
                  email: email ? email : userDetail.email,
                  modified: true,
                  modifiedemail:
                    group === "usersmodifyemail" ||
                    userDetail.modifiedemail === true
                      ? true
                      : false,
                }
              : userDetail
          ),
        },
      }
    } else if (group === "usersadd") {
      return {
        ...state,
        isLoading: false,
        company: {
          ...state.company,
          users: [
            ...state.company.users,
            { id: "", fname: "", lname: "", email: "", add: true },
          ],
        },
      }
    } else if (group === "usersdelete" || group === "usersdeleteundo") {
      return {
        ...state,
        isLoading: false,
        company: {
          ...state.company,
          users: state.company.users.map((userDetail, i) =>
            i === index
              ? {
                  ...userDetail,
                  delete: group === "usersdelete" ? true : false,
                }
              : userDetail
          ),
        },
      }
    }
  }

  if (action.type === "TEMPLATE_TAGS_START") {
    console.log("TEMPLATE_TAGS_START")
    return {
      ...state,
      isLoadingTags: true,
    }
  }
  if (action.type === "TEMPLATE_TAGS_UPDATE") {
    console.log("TEMPLATE_TAGS_UPDATE")
    return {
      ...state,
      isLoadingTags: false,
      templatetags: action.data,
    }
  }
  if (action.type === "IMAGEUPLOAD_START") {
    console.log("IMAGEUPLOAD_START")
    return {
      ...state,
      isLoadingImageUpload: true,
    }
  }
  if (action.type === "IMAGEUPLOAD_PROGRESS") {
    console.log("IMAGEUPLOAD_PROGRESS")
    return {
      ...state,
      imageuploadprogress: {
        ...state.imageuploadprogress,
        [action.data.prop]: { progress: action.data.val },
      },
    }
  }
  if (action.type === "IMAGEUPLOAD_FINISH") {
    console.log("IMAGEUPLOAD_FINISH")
    return {
      ...state,
      isLoadingImageUpload: false,
      imageuploadprogress: {
        ...state.imageuploadprogress,
        [action.data.prop]: { progress: action.data.val, loc: action.data.loc },
      },
    }
  }
  if (action.type === "IMAGEUPLOAD_RESET") {
    console.log("IMAGEUPLOAD_RESET")
    return {
      ...state,
      isLoadingImageUpload: false,
      isLoadingImageDelete: false,
      imageuploadprogress: {},
      imagetags: [],
      forms: {
        ...state.forms,
        saveimage: {},
        deleteimage: [],
      },
    }
  }

  if (action.type === "IMAGEDELETE_START") {
    console.log("IMAGEDELETE_START")
    return {
      ...state,
      isLoadingImageDelete: true,
    }
  }

  if (action.type === "IMAGEDELETE_FINISH") {
    console.log("IMAGEDELETE_FINISH")
    return {
      ...state,
      isLoadingImageDelete: "complete",
      forms: {
        ...state.forms,
        deleteimage: [],
      },
    }
  }
  if (action.type === "IMAGEDELETE_COMPLETE") {
    console.log("IMAGEDELETE_COMPLETE")
    return {
      ...state,
      isLoadingImageDelete: false,
    }
  }

  if (action.type === "USERGROUP_CREATE_UPDATE_START") {
    console.log("USERGROUP_CREATE_UPDATE_START")

    return {
      ...state,
      isLoadingUserGroup: true,
    }
  }

  if (action.type === "USERGROUP_CREATE_UPDATE_COMPLETE") {
    console.log("USERGROUP_CREATE_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingUserGroup: false,
      userGroupUpdateComplete: true,
    }
  }

  if (action.type === "USERGROUP_CREATE_UPDATE_RESET") {
    console.log("USERGROUP_CREATE_UPDATE_RESET")

    return {
      ...state,
      userGroupUpdateComplete: false,
    }
  }

  if (action.type === "TEMPLATETRAY_FIELD_UPDATE") {
    console.log("TEMPLATETRAY_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      templateTrayIsCollapsed: action.data,
    }
  }

  if (action.type === "IMAGELIBRARY_CONTEXT_REPLACE") {
    console.log("IMAGELIBRARY_CONTEXT_REPLACE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      imagelibrary: action.data,
    }
  }
  if (action.type === "USERPROFILE_CREATE_UPDATE_START") {
    console.log("USERPROFILE_CREATE_UPDATE_START")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === "CONV_START") {
    console.log("CONV_START")
    return {
      ...state,
      conversationActive: true,
    }
  }
  if (action.type === "CONV_END") {
    console.log("CONV_END")
    return {
      ...state,
      conversationActive: false,
    }
  }
  if (action.type === "CONV_CONTEXT") {
    console.log("CONV_CONTEXT")
    return {
      ...state,
    }
  }

  if (action.type === "SOCIAL_FIELD_UPDATE") {
    console.log("SOCIAL_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      social: {
        ...state.social,
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "SOCIAL_MODAL_USERDETAIL_CLEAR") {
    console.log("SOCIAL_MODAL_USERDETAIL_CLEAR")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      social: {
        ...state.social,
        usertemplates: [],
        userdata: [],
      },
    }
  }

  if (action.type === "LOADING_FOLLOW_START") {
    console.log("LOADING_FOLLOW_START")
    return {
      ...state,
      isLoadingFollowCreate: true,
    }
  }
  if (action.type === "LOADING_FOLLOW_UPDATE") {
    console.log("LOADING_FOLLOW_UPDATE")
    return {
      ...state,
      isLoadingFollowCreate: false,
      userdetail: {
        ...state.userdetail,
        follow: action.data,
      },
    }
  }

  return state
}

const initialTemplateCreateError = {
  screenername: false,
  screenerkeyword: false,
  screenerpushcampaignlength: false,
  screenerpushcampaignitems: [],
  screenerpushcampaignitemserror: false,
  prompttext: false,
  screenerlogo: false,
  imagewidth: false,
  imagesize: false,
  imageextension: false,
  uploadtext: false,
  uploadsubtext: false,
  surveylength: false,
  surveyitems: [],
  surveyerror: false,
}

const initialTemplateCreate = {
  inputmode: "create",
  survey: [],
  surveycounter: 1,
  criteria: [],
  criteriacounter: 1,
  landing: {
    landingdata: [{ lang: "en", uploadtext: "", uploadsubtext: "" }],
  },
  prompts: [{ lang: "en", data: [{ text: "" }] }],
  screener: {
    mode: "response",
    keyword: "",
    keywordorig: "",
    pushcampaign: [],
    pushcampaignorig: [],
  },
  error: initialTemplateCreateError,
}

const initialSendGroupCreateError = {
  sendgroupname: false,
  sendgroupuserslength: false,
  sendgroupusersitems: [],
  sendgroupuserserror: false,
}

const initialSendGroupCreate = {
  users: [],
  deliver: {
    calchour: 9,
    meridian: "am",
    rawhour: 9,
    timezone: "US/Mountain",
    utchrs: 16,
    utcmins: 960,
  },
  sendgroup: { name: "" },
  error: initialSendGroupCreateError,
}

const ts = Math.round(new Date().getTime() / 1000)

// const tempGlobalLayers = [
//   {
//     pos: 1,
//     id: "layer1",
//     mode: "video",
//     name: "Base video",
//   },
//   {
//     pos: 2,
//     id: "layer2",
//     mode: "image",
//     name: "Overlay",
//   },
// ]

const tempElements = [
  // {
  //   shape: "rect",
  //   x: 0,
  //   y: 0,
  //   width: 500,
  //   height: 500,
  //   fill: "white",
  //   id: "rect1",
  // },
]
const initialPublisherCreate = {
  mode: "image",
  imagemode: "resize",
  templates: [],
  loadedScenes: [],
  sceneSelected: {},
  templateSelected: "",
  elements: tempElements,
  sceneLayerSelected: {},
  elementSelected: {},
  stage: {
    id: "instagram",
    name: "Instagram Post",
    width: 700,
    height: 700,
    export: {
      w: 1080,
      h: 1080,
      scaleW: 700,
      scaleH: 700,
      x: 100 / 2,
      y: 100 / 2,
    },
  },
  webp: true,
  // error: initialTemplateCreateError,
}

const initialState = {
  loginMessage: "",
  isLoading: false,
  isLoadingMediaPreview: false,
  isLoadingNotifyCreate: false,
  isLoadingTemplateCreate: false, //used
  isLoadingTemplateFetch: false, //used
  isLoadingTemplateDeleteCompleted: false, //used
  isLoadingImageUpload: false, //used
  isLoadingImageDelete: false, //used
  isLoadingVideoCreatedGrouped: false,
  isLoadingVideoUsers: false,
  isLoadingVideoMini: false,
  isLoadingCanvasImage: false,
  isLoadingUserGroup: false,
  isLoadingTags: false,
  isLoadingFollowCreate: false,
  isProfileUpdateComplete: false,
  publisher: initialPublisherCreate,
  social: { keywordtemplates: [], usertemplates: [], userdata: {} },
  notify: [],
  userdetail: {
    follow: [],
  },
  userdetailemail: {
    email: "",
    mobile: "",
    verifycode: "",
    displayname: "",
  },
  userlogin: {},
  usersub: "",
  company: {
    // users: [] // keep this commented or will mess up componentDidMount check in consoleHome
  },
  forms: {
    notify: {},
    savetemplate: {},
    edittemplate: {},
    tag: {},
    workingtags: [],
    saveimage: {}, //used on imageLibraryFormUpload
    deleteimage: [], //used on imagePreviewTray
    previewImageLoaded: false,
    social: { searchkeyword: "" },
    usersadd: [],
    usersdelete: [],
    usersmodify: [],
    userdetail: {},
    userdetailemail: {
      email: "",
      mobile: "",
      verifycode: "",
      displayname: "",
      error: "",
    },
  },
  templatetags: [],
  imagetags: [],
  share: {},
  imageuploadprogress: 0,
  imageexport: {},
  imagelibrary: "brand",
  activeTool: "canvas",
  sendgroupcreate: initialSendGroupCreate,
  emailVerifyMode: false,
  userGroupUpdateComplete: false,
  templateTrayIsCollapsed: false,
  conversationActive: false,
}

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk))

export default createStore
